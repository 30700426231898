import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Apollo, gql } from "apollo-angular";

import ALL_BLOGS_QUERY from "../apollo/queries/blog/all-blogs";

import BLOG_QUERY from "../apollo/queries/blog/blog";
import BLOGS_QUERY from "../apollo/queries/blog/blogs";
import YEARS_QUERY from "../apollo/queries/blog/blog-years";
import CATEGORIES_QUERY from "../apollo/queries/blog/blog-categories";



@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private apollo:Apollo) { }

  public getAllBlogs(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: ALL_BLOGS_QUERY,
      variables: {
        category: "",
        year: "",
      },
    }).valueChanges;
  }
  public getBlogCategories(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: CATEGORIES_QUERY
    }).valueChanges;
  }
  public getBlogYears(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: YEARS_QUERY
    }).valueChanges;
  }
  public getFilteredBlogs(category:string, year:string): Observable<any>{
    if(category=="All"){
      category="";
    }
    if(year== "All Time"){
      year="";
    }
    return this.apollo.watchQuery<any>({
      query: BLOGS_QUERY,
      variables: {
        category: category,
        year: year,
      },
    }).valueChanges;
  }
  public getBlogPages(blogs:any[]){
      var maxPage=Math.ceil((blogs.length+2)/6);
      var index=1;
      var pages=[];
      while(index <= maxPage){
        pages=pages.concat(index);
        index++
      }
      return pages;
  }
  public getFilteredBlogPages(blogs:any[]){
    var maxPage=Math.ceil((blogs.length)/6);
    var index=1;
    var pages=[];
    while(index <= maxPage){
      pages=pages.concat(index);
      index++
    }
    return pages;
}
  public getBlogsFromPage(blogs:any[], page){
    var index= parseInt(page)-1;
    if (index != 0){
      return blogs.slice(index*6-2, index*6+4);
    }else{
      return blogs.slice(0,4)
    }
  }
  public getFilteredBlogsFromPage(blogs:any[], page){
    var index= parseInt(page)-1;
    return blogs.slice(index*6, index*6+6);
  }
  public getBlog(slug){
    return this.apollo.watchQuery<any>({
      query: BLOG_QUERY,
      variables: {
        slug: slug,
      },
    }).valueChanges;
  }

}
