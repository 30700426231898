import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { CapabilitiesComponent } from './components/capabilities/capabilities.component';

import { CatalogComponent } from './components/catalog/catalog.component';
import { ProductBrowseComponent } from './components/catalog/product-browse/product-browse.component';
import { ProductComponent } from './components/catalog/product-browse/product/product.component';

import { BlogComponent } from './components/blog/blog.component';
import { BlogContentComponent } from './components/blog/blog-content/blog-content.component';

import { CertifiationsComponent } from './components/certifiations/certifiations.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
// import { ContactComponent } from './components/contact/contact.component';
// import { JobInquiriesComponent } from './components/job-inquiries/job-inquiries.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'capabilities', component: CapabilitiesComponent },
  // { path: 'catalog', component: CatalogComponent },
  { path: 'catalog/:collection', component: ProductBrowseComponent },
  // { path: 'catalog/product/:id', component: ProductComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogContentComponent },
  { path: 'initiatives', component: CertifiationsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  // { path: 'contact', component: ContactComponent },
  // { path: 'job-inquiries', component: JobInquiriesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
