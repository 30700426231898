import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtil {

  //static SERVER_URL: string = "http://localhost:1337";
  static SERVER_URL: string = "https://strapi.rivaprecision.com";
  constructor() 
  { 

  }

}