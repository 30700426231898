import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestimonialsComponent implements OnInit {

  constructor(public aboutService: AboutService,) { }
  loading = true;
  testimonialQuery:any;
  testimonials: any[];
  urlPrefix="https://strapi.rivaprecision.com";
  ngOnInit(): void {
    this.testimonialQuery = this.load();
  }
  ngOnDestroy() {
    this.testimonialQuery.unsubscribe();
  }
  load(){
    return this.aboutService.getTestimonials().subscribe(((result)=>{
      this.testimonials= result.data.testimonials;
      this.loading= false;
    }))
  }


}
