<div id="main">
  <span class="mobhide"></span>
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="title">Blog</div>
      <div class="overlay"></div>
    </div>
  </div>

  <div id="group2">
    <div class="container" style="padding: 15px">
      <div *ngIf="!this.loading" class="row">
        <div class="col-12">
          <div class="latest">
            <div class="flex">
              <img [src]="this.urlPrefix + this.blog.image[0].url" alt="" />
              <div class="card">
                <h2>{{ this.blog.blog_categories[0].category }}</h2>
                <h6>{{ this.blog.datePosted }}</h6>
                <h1>{{ this.blog.title }}</h1>
                <span class="mobhide">
                  <p>{{ this.blog.teaser }}</p></span
                >
                <h2 class="byline">by {{ this.blog.byline }}</h2>
              </div>
            </div>
          </div>
          <div class="row" style="padding: 16px">
            <div class="card">
              <markdown
                ngPreserveWhitespaces
                [data]="this.blog.content"
                class="content"
              ></markdown>
            </div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4 pagination">
              <a
                class="underline-on-hover blog-link"
                (click)="this.router.navigate(['blog'])"
                >BACK TO MAIN BLOG</a
              >
            </div>
            <div class="col-4"></div>
          </div>
        </div>

        <!-- <div id= "col-2" class="col-2 filters">
                 <h4>Categories</h4>
                 <ul *ngIf='this.blogCategories'>
                   <li *ngFor="let cat of this.blogCategories" > 
                     <a class="underline-on-hover" 
                       (click)="this.router.navigate(['blog',{category: cat.id , year: 0, page: 1} ])">
                         {{cat.category}} 
                     </a>
                   </li>
                 </ul>
                   
                 <h4>Year</h4>
                 <ul *ngIf='this.blogYears'>
                   <li *ngFor="let yr of this.blogYears" > 
                     <a class="underline-on-hover" 
                       (click)="this.router.navigate(['blog',{category: 0, year:  yr.id , page: 1} ])">
                       {{yr.year}}
                     </a>
                   </li>
                 </ul>
               </div>
             </div>-->
      </div>
    </div>
  </div>
</div>
