import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from "apollo-angular";
import { HttpClient } from '@angular/common/http';

import COLLECTIONS_QUERY from "../apollo/queries/products/collections";
import COLLECTION_QUERY from "../apollo/queries/products/collection";
import PRODUCT_QUERY from "../apollo/queries/products/product";
import { CommonUtil } from './commonUtil.service';



@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private apollo:Apollo, private http: HttpClient) { }
  
  public getProductCollections(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: COLLECTIONS_QUERY
    }).valueChanges;
  }
  public getProductCollection(id:string): Observable<any>{
    let url: string = CommonUtil.SERVER_URL + "/product-collections/" + id;
    return this.http.get<any>(url);
    /*return this.apollo.watchQuery<any>({
      query: COLLECTION_QUERY,
      variables: {
        collectionId: id,
      },
    }).valueChanges;*/
  }
  public getProduct(slug:string): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: PRODUCT_QUERY,
      variables: {
        slug: slug,
      },
    }).valueChanges;
  }
  public getFilteredProducts(filters:any,products:any){
    for(let filter in filters){
      if(filters[filter]==""){continue;}
      products = products.filter(product =>{
        return product.product_filter_values.some(({id}) => id === filters[filter])
      })
    }
    return products;
  }


}
