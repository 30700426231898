<div *ngIf="!this.loading" id="main">
  <div
    id="group1"
    class="parallax__group"
    [ngStyle]="{
      'background-image':
        'url(' + this.urlPrefix + this.collection.CoverPhoto.url + ')'
    }"
  >
    <div class="parallax__layer parallax__layer--base">
      <div class="title">{{ this.collection.name }}</div>
      <div class="overlay"></div>
    </div>
  </div>

  <div
    id="group2"
    [ngStyle]="{
      'background-image':
        'url(' + this.urlPrefix + this.collection.BackgroundPhoto.url + ')'
    }"
  >
    <div class="divider container-fluid">
      <div class="row">
        <div class="col-12 info">
          <markdown
            ngPreserveWhitespaces
            [data]="this.collection.Description"
            class="content"
          >
          </markdown>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 links">
          <a
            class="underline-on-hover"
            (click)="this.router.navigate(['home'])"
          >
            HOME</a
          >
          <a
            class="underline-on-hover"
            (click)="this.router.navigate(['catalog'])"
            >> CATALOG</a
          >
          <a>> {{ this.collection.name.toUpperCase() }}</a>
        </div>

        <div class="col-12 justify-content-center filters">
          <ng-template ngFor let-filter [ngForOf]="this.filters">
            <select
              class="btn dropdown-toggle"
              name="filter.name"
              #Filter
              (change)="
                this.selectedFilters[filter.name] = Filter.value;
                this.filterProducts()
              "
            >
              <option class="dropdown-item" value="" selected>
                {{ filter.displayName }}
              </option>
              <option
                class="dropdown-item"
                *ngFor="let option of filter.product_filter_values"
                [value]="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </ng-template>
        </div>
      </div>
    </div>

    <!--<div class="masonry-container container-fluid" style="display:inherit;">
            <ngx-masonry  class="masonry"  [options]="masonryOptions" [ordered]="true">
    
              <div ngxMasonryItem *ngFor="let product of this.products" 
                  [ngStyle]="{'background-image': 'url('+ this.urlPrefix + product.hoverImage[0].url + ')'}" 
                  class="grid-item"   
                  (click)="navigateToProduct(product)">
              <img [src]="this.urlPrefix + product.coverImage.url"  class="productPic1" style="width:100%">
              <div class="overlay">
                  <div class="item-description">
                  {{product.shortName}}
                  </div>
              </div>
              </div>
            </ngx-masonry>
        </div>-->
    <div class="gallery">
      <div class="row">
        <div
          *ngFor="let product of this.products"
          class="gallery-item col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"
          [ngStyle]="{
            'background-image':
              'url(' + this.urlPrefix + product.hoverImage[0].url + ')'
          }"
          (click)="navigateToProduct(product)"
        >
          <img
            [src]="urlPrefix + product.coverImage.url"
            class="productPic1"
            style="width: 100%"
          />
          <div class="overlay">
            <div class="item-description">
              {{ product.shortName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading-backdrop"></div>
