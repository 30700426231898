<div id="main">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="title">FUSING ENGINEERING & ART</div>
      <div class="overlay"></div>
      <!-- <img class="title" src="../../../assets/images/Logos for the website-02.png" alt="Purple Antler logo text Fusing Art & Engineering" > -->
    </div>
  </div>
  <div id="group2" class="parallax__group">
    <div class="videoclip">
      <div id="vidthumb" (click)="hidethumb()" *ngIf="displayVid">
        <button class="btnPlay">
          <i class="fa fa-play" (click)="hidethumb()"></i>
        </button>
      </div>
      <iframe
        allow="autoplay;"
        id="vidframe"
        wmode="Opaque"
        width="900"
        height="445"
        src=""
      >
      </iframe>
    </div>

    <div class="parallax__layer parallax__layer--base">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 info-box">
            <h5 class="info-header">Our Values</h5>
            <h2 class="info-subject">Quality Unparalleled</h2>
            <p class="info-content">
              RIVA is built on the concept of servant leadership. We have found
              ourselves at the forefront of jewelry manufacturing by serving and
              supporting growth in both our customers and our employees. Our
              commitment to detail and excellence is founded on this concept and
              guided by our mission: to honor God through our service, process
              and product excellence.
            </p>
            <div class="image-and-button-container">
              <div class="mobile-images">
                <img
                  src="https://strapi.rivaprecision.com/uploads/dreamstime_m_4569264_8e5624c93e.jpg"
                />

                <img
                  src="https://strapi.rivaprecision.com/uploads/shutterstock_441060223_ce891cb46d.jpg"
                />
              </div>
              <div>
                <a class="info-learnmore" href="/about"
                  ><span>Learn More</span></a
                >
              </div>
            </div>
          </div>
          <span class="col-lg-6 image-box mobhide">
            <div class="info-image">
              <div class="image1"></div>

              <img
                class="image2"
                src="https://strapi.rivaprecision.com/uploads/dreamstime_m_4569264_8e5624c93e.jpg"
              />

              <img
                class="image3"
                src="https://strapi.rivaprecision.com/uploads/shutterstock_441060223_ce891cb46d.jpg"
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div id="group3" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="container">
        <div class="row" style="padding-top: 0 !important">
          <div class="col-xl-6 info-box">
            <h5 class="info-header">Our Capabilities</h5>
            <h2 class="info-subject">Inventive Problem Solvers</h2>
            <p class="info-content">
              Our client relationships constantly shape us into who we are. Over
              the years, we have grown according to our partner’s needs.
              Constant adaptation to the latest technology, investing in
              inventive problem solving and excellent customer service are what
              makes both sides of a partnership thrive.
            </p>
            <div class="image-and-button-container">
              <div class="mobile-images">
                <img
                  src="https://api.rivapm.com/images/shutterstock_475269856-e1562712961475.jpg"
                />
              </div>
              <div>
                <a class="info-learnmore" href="/capabilities"
                  ><span>Learn More</span></a
                >
              </div>
            </div>
          </div>
          <span class="col-xl-6 info-image mobhide"></span>
        </div>
      </div>
    </div>
  </div>

  <div id="group4" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 info-box">
            <h5 class="info-header">Our Collections</h5>
            <h2 class="info-subject">White Label Solutions</h2>
            <p class="info-content">
              Apart from working with our customers and partners to bring their
              ideas to life, we also design and develop our own collections of
              classic and time-tested pieces/styles that jewelers can pick from
              to expand and/or replenish their inventories.
              <span class="mobhide"
                >By making these White Label collections accessible to our
                clients, they, in turn, can satisfy their customers’ needs all
                year round. Development for each piece in these collections are
                guided by RIVA’s design principles which put ethical sourcing,
                sustainability and social responsibility first.</span
              >
            </p>
            <div class="image-and-button-container">
              <div class="mobile-images">
                <img
                  src="https://api.rivapm.com/images/catalog/home/IMG_0961_crop.jpg"
                />
              </div>
              <div>
                <a
                  class="info-learnmore"
                  (click)="navigateToUrl('https://shop.rivaprecision.com')"
                  ><span>Explore</span></a
                >
              </div>
            </div>
          </div>
          <span class="col-xl-6 info-image mobhide"></span>
        </div>
      </div>
    </div>
  </div>
  <div id="group5" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img class="info-image" />
          </div>
          <div class="col-lg-6 info-box" style="padding: 0 50px">
            <h5 class="info-header">Our Home</h5>
            <h2 class="info-subject">Brooklyn Made</h2>
            <h5 class="info-subheader">With The World's Finest Materials</h5>
            <p class="info-content">
              Our service has always depended on the availability of talented
              craftsmen that New York City has to offer. Although many
              manufacturing plants have moved overseas, we have planted our feet
              firmly at home.
              <span class="mobhide"
                >Manufacturing during the fashion world gives our clients the
                chance to visit our factory, touch their product, and see
                firsthand the artistry behind the art.</span
              >
            </p>
            <a class="info-learnmore" href="/about"><span>Learn More</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="divNewsLetter"
    class="row d-flex align-items-center"
    style="width: calc(100% + 15px); justify-content: center"
  >
    <div class="col-xs-8">
      <div class="newsletter-text">
        <h2
          style="
            color: #eed253;
            font-family: Alegreya Sans;
            font-weight: 300 !important;
          "
        >
          Newsletter
        </h2>
        <h4>Sign up for our exclusive newsletter</h4>
      </div>
    </div>
    <div class="col container">
      <!-- href="https://mailchi.mp/3608919bad02/rivaprecision"  -->
      <a
        (click)="setDialogVisibility()"
        class="btn btn-newsletter-signup center-position"
        style="position: absolute; width: 215px; height: 66px; font-size: 16px"
      >
        Sign Up Now
      </a>
    </div>
  </div>
</div>
<!-- <app-hubspot-email-signup
  *ngIf="showDialog"
  (onHideDialog)="setDialogVisibility()"
></app-hubspot-email-signup> -->
