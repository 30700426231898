import gql from "graphql-tag";

const HISTORY_CARDS_QUERY = gql`
query Cards {
    ourHistoryCards
    {
      id
      year
      info
      image {
        url
        alternativeText
      }
    }
  }
`;

export default HISTORY_CARDS_QUERY;