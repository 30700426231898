import gql from "graphql-tag";

const YEARS_QUERY = gql`
query blogYears{
    blogYears( 
        sort:"id:desc"
    )
    {
      id
      year
    }
  }
`;

export default YEARS_QUERY;