import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { Apollo, gql } from "apollo-angular";
import PAGE_TAGS_QUERY from "../apollo/queries/seo/page";
@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private apollo:Apollo, private title: Title, private meta: Meta) { }


  //Update Global Website Meta-Data

  private updateTitle(title: string) {
    this.title.setTitle(title);
  }
  private updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
  private updateKeywords(keywords: string){
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  //Open Graph Tags
  
  private updateOgTitle(title: string) {
    this.meta.updateTag({ name: 'og:title', content: title })
  }
  private updateOgType(type: string){
    this.meta.updateTag({ name: 'og:type', content: type })
  }
  private updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }
  private updateOgImage(image: string) {
    this.meta.updateTag({ name: 'og:image', content: image })
  }
  private updateOgDescription(image: string) {
    this.meta.updateTag({ name: 'og:description', content: image })
  }
  
  //Blog Article Specific Open Graph Tags

  private updateArticlePublished(date: string){
    this.meta.updateTag({ name: 'article:published_time', content: date })
  }
  private updateArticleAuthor(author: string){
    this.meta.updateTag({ name: 'article:author', content: author })
  }
   private updateArticleSection(section: string){
    this.meta.updateTag({ name: 'article:section', content: section })
  }
  private addArticleTag(tag: string){
    this.meta.addTag({ name: 'article:tag', content: tag })
  }

  //Product Specific Open Graph Tags

  private updateProductPluralTitle(title: string){
    this.meta.addTag({ name: 'product:plural_title', content: title })
  }
  private updateProductPrice(price: string){
    this.meta.addTag({ name: 'product:price.amount', content: price })
  }
  private updateProductCurrency(){
    this.meta.addTag({ name: 'product:price.currency', content: "USD" })
  }

  public getPageMetaTags(page:string): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: PAGE_TAGS_QUERY,
      variables: {
        page: page,
      },
    }).valueChanges;
  }

  public updatePageMetaTags(title:string, url:string, keywords:string, image:string, description:string){
    this.updateTitle(title);
    this.updateOgTitle(title);
    this.updateOgType('website');
    this.updateOgUrl(url);
    this.updateKeywords(keywords);
    this.updateOgImage(image);
    this.updateDescription(description);
    this.updateOgDescription(description);
  }




  public updateArticleMetaTags(title:string, url:string, keywords:string, image:string, description:string, date:string, author: string, section: string, tags:string[]){
    this.updateTitle(title);
    this.updateOgTitle(title);
    this.updateOgType('article');
    this.updateOgUrl(url);
    this.updateKeywords(keywords);
    this.updateOgImage(image);
    this.updateDescription(description);
    this.updateOgDescription(description);
    this.updateArticlePublished(date);
    this.updateArticleAuthor(author);
    this.updateArticleSection(section);
    tags.forEach(tag => this.addArticleTag(tag));

  }

  public updateProductMetaTags(title:string, url:string, image:string, desc:string, price:string){
    this.updateTitle(title);
    this.updateOgTitle(title);
    this.updateOgType('product');
    this.updateProductPluralTitle(title+"s")
    this.updateOgUrl(url);
    this.updateOgImage(image);
    this.updateDescription(desc);
    this.updateOgDescription(desc);
    this.updateProductPrice(price);
    this.updateProductCurrency();
  }

  public updatePageSEO(page:string, url:string){
    this.getPageMetaTags(page).subscribe((result) =>{
      let seo = result.data.seoPageMetaTags[0];
      let urlPrefix = "https://strapi.rivaprecision.com";
      this.updatePageMetaTags(seo.title, url, seo.keywords, urlPrefix + seo.image[0].url, seo.description)
    })
  }
  public updateCollectionSEO(title:string, url:string, keywords:string, description:string, image:string){
    
  }

}
