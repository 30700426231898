<div *ngIf ="!this.loading"  id="main"  >
    <div id="product-details" class="product-details container-fluid" [ngStyle] = "{'background-image': 'url(' + this.urlPrefix + this.product.product_collection.BackgroundPhoto.url+')'}">
        <div class="row product-title">
            <h1>{{this.product.fullName}}</h1>
            
        </div>
        <div class="row product-sku">
            <h2>SKU: {{this.product.SKU}}</h2>
            <div class="col-12">
                <a class="underline-on-hover" (click)="this.router.navigate(['home'])"> HOME</a>
                <a class="underline-on-hover" (click)="this.router.navigate(['catalog'])">> CATALOG</a>
                <a class="underline-on-hover" (click)="this.router.navigate(['catalog/'+this.product.product_collection.id])">> {{this.product.product_collection.name.toUpperCase()}}</a>
                <a class="underline-on-hover" href="mailto:info@rivaprecision.com">> REQUEST INFO</a>
            </div>
        </div>
        <div class="row allign-items-center">
            
            <div *ngIf="this.product.images.length > 1" class="col-3 col-lg-1 product-image-list" >
                <ng-container *ngFor="let image of this.product.images">
                    <video *ngIf="image.url.slice(-4) == '.mp4'"  class="img-thumbnail" (click)="imageSelected= this.urlPrefix + image.url"  [ngStyle]="{'background-color': (this.imageSelected==this.urlPrefix + image.url) ? '#eed253' : '#ffffff' }">
                        <source src="{{this.urlPrefix + image.url}}" type="video/mp4">
                    </video>

                    <img *ngIf="image.url.slice(-4) != '.mp4'"  class="img-thumbnail" src="{{this.urlPrefix + image.url}}" (click)="imageSelected= this.urlPrefix + image.url" [ngStyle]="{'background-color': (this.imageSelected==this.urlPrefix + image.url) ? '#eed253' : '#ffffff' }">
                </ng-container>
                
            </div>
            <div class="col-9 col-lg-5 product-image">
                <video *ngIf="imageSelected.slice(-4) == '.mp4'"  class="img-selected" autoplay mute loop>
                    <source src="{{imageSelected}}" type="video/mp4">
                </video>
                <img *ngIf="imageSelected.slice(-4) != '.mp4'" id="product-image" class="img-selected"src="{{imageSelected}}">
            </div>
            <div class="col-12 col-lg-5 product-info">
                <div class="row product-description">
                    {{this.product.description}}
                </div>

                <div *ngFor="let attribute of this.product.productAttributes | keyvalue"> 
                    <p> {{attribute.key}}: 
                        <span *ngIf="!this.isArray(attribute.value)"   class="bold">{{attribute.value}}</span>
                    </p>
                    <ul *ngIf="this.isArray(attribute.value)">
                        <li *ngFor="let item of attribute.value" >{{item}}</li>
                    </ul>
                
                </div>


                <div *ngIf ="this.product.priceEnabled" class="product-price">$ {{this.product.price.toFixed(2)}}</div>
                <div class="row price-disclaimer">
                    <markdown ngPreserveWhitespaces [data]="this.product.disclaimer"> </markdown>
                    
                </div>
                
                
            </div>
            <div class="col-1"></div>



        </div>
    </div>
    <div class="container-fluid">
        <div class="row learn-more">
            <p>Interested in this product? Send us a message!</p>
            <a  href="mailto:info@rivaprecision.com">
                Contact Us
            </a>
        </div>
    </div>
    
</div>