<div id="main">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="title">Capabilities</div>
      <!-- <img class="title" src="../../../assets/images/Logos for the website-02.png" alt="Purple Antler logo text Fusing Art & Engineering" > -->
      <div class="overlay"></div>
    </div>
  </div>

  <div id="group2" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="overlay"></div>
      <div class="container" style="margin-top: -10vw">
        <div class="row">
          <div class="col-12">
            <div class="content-center">
              <div class="video-container">
                <iframe
                  src="https://api.rivapm.com/images/capabilities/Lapidary20s.mp4"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          <div *ngIf="!this.loading" class="col" style="margin-top: 120px">
            <div
              *ngFor="let capability of this.leftCapabilities; let last = last"
              class="row"
            >
              <div class="col-10">
                <div class="content-left">
                  <h4>{{ capability.label }}</h4>
                  <p>
                    {{ capability.body }}
                  </p>
                </div>
              </div>
              <div class="col-2">
                <div class="item-point"></div>
                <div *ngIf="!last" class="item-line"></div>
              </div>
            </div>
          </div>

          <div *ngIf="!this.loading" class="col" style="margin-top: 30px">
            <div
              *ngFor="let capability of this.rightCapabilities; let last = last"
              class="row"
            >
              <div class="col-2">
                <div class="item-point"></div>
                <div *ngIf="!last" class="item-line"></div>
              </div>
              <div class="col-10">
                <div class="content-right">
                  <h4>{{ capability.label }}</h4>
                  <p>
                    {{ capability.body }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="parallax__layer parallax__layer--base">
        <div class="overlay"></div>
    </div> -->
  </div>

  <div class="contact-us-container">
    <div class="container">
      <div class="row container">
        <span class="mobhide"
          ><div class="col">Interested in learning more?</div></span
        >
        <div class="col">
          <a class="contact-us" href="/contact">
            <span>Contact Us</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
