import gql from "graphql-tag";

const PAGE_TAGS_QUERY = gql`
query PageMetaTag($page: String!){
    seoPageMetaTags(where: {page: $page}){
      title
      description
      keywords
      image{
        url
      }
    }
  }
`;

export default PAGE_TAGS_QUERY;