import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params} from '@angular/router';
import { BlogsService } from 'src/app/services/blogs.service';
import { Subscription } from "rxjs";
import { SeoService } from 'src/app/services/seo.service';
import { CommonUtil } from 'src/app/services/commonUtil.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  title = 'Blogs - Riva Precision | American Jewelry Manufacturer';
  firstBlog:any ={};
  allBlogs:any = [];
  filteredBlogs:any=[];
  blogs:any = [];
  blogCategories:any = [];
  blogYears:any = [];
  pages:number[];
  selected={ "category": {"id":0, "category": "All"}, "year": {"id":0, "year": "All Time"}};
  pageSelected:number=1;
  urlPrefix= CommonUtil.SERVER_URL;
  data: any = {};
  loading = true;
  blogsLoading = true;
  errors: any;

  private queryBlogs: Subscription;

  constructor(private route:ActivatedRoute,
              private router:Router,
              private blogService: BlogsService,
              public _seoService: SeoService) { 
      //this._seoService.updateTitle(this.title);
  }
  sub
  ngOnInit() {
    
    this._seoService.updatePageSEO('blog', window.location.href);
    this.queryBlogs = this.loadBlogs();
    window.scrollTo(0, 0);
    
  }
  ngOnDestroy() {
    this.queryBlogs.unsubscribe();
  }
  loadBlogs(){
    return this.blogService.getAllBlogs().subscribe((result) => {
      this.allBlogs = result.data.blogs;
      this.blogsLoading = result.loading;
      this.errors = result.errors;
      this.blogYears = result.data.blogYears;
      this.blogCategories = result.data.blogCategories;
      this.load();
    });
  }
  loadFilteredBlogs(){
    return this.blogService.getFilteredBlogs(this.selected.category.category, this.selected.year.year).subscribe((result) => {
      this.filteredBlogs = result.data.blogs;
      this.blogsLoading = result.loading;
      this.errors = result.errors;
      this.pages = this.blogService.getFilteredBlogPages(this.filteredBlogs);
      this.blogs = this.blogService.getFilteredBlogsFromPage(this.filteredBlogs, this.pageSelected);
    });
  }
  load(){
    this.route.params.subscribe((params: Params) =>  {

      if(params.category && params.category != 0){
        this.selected.category = this.blogCategories.find(e=>e.id == params.category);
      }else{
        this.selected.category = {"id":0, "category": "All"};
      }

      if(params.year && params.year != 0){
        this.selected.year = this.blogYears.find(e=>e.id == params.year);
      }else{
        this.selected.year = {"id":0, "year": "All Time"};
      }

      if(params.page){
        this.pageSelected = parseInt(params.page);
      }else{
        this.pageSelected=1;
      }
     
      if(this.selected.category.category =='All' && this.selected.year.year=='All Time'){
        this.pages = this.blogService.getBlogPages(this.allBlogs);
        this.blogs = this.blogService.getBlogsFromPage(this.allBlogs, this.pageSelected);
        if(this.pageSelected==1){
          this.firstBlog =this.blogs[0];
          this.blogs = this.blogs.slice(1)
        }
      }else{
        this.queryBlogs =this.loadFilteredBlogs();
      }
      
      });
  }

  navigateToBlog(blog: any)
  {
    if(blog)
    {
      if(blog.Slug)
      {
        this.router.navigate(['blog/'+ blog.Slug])
      }
      else
      {
        console.error("Slug for Blog ID " + blog.id + "  is null");
      }
    }
    
  }

}
