import { Component, ViewEncapsulation,  OnInit } from '@angular/core';

import { Router,ActivatedRoute, Params} from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription, Observable } from "rxjs";
import { SeoService } from 'src/app/services/seo.service';
import { CommonUtil } from 'src/app/services/commonUtil.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  title = ' Catalog - Riva Precision | American Jewelry Manufacturer';
  
  urlPrefix= CommonUtil.SERVER_URL;
  id: string;
  product:any = {};
  loading = true;
  errors: any;
  imageSelected: string;
  private queryProduct: Subscription;
  constructor(private route:ActivatedRoute,
    private router:Router,
    public _seoService: SeoService,
    private productsService: ProductsService) { }
    sub 
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.load();
  }
  
  ngOnDestroy() {
    this.queryProduct.unsubscribe();
  }
  load(){
    this.route.params.subscribe((params: Params) =>  
      {
       this.id = params.id;
       this.queryProduct = this.loadProduct(this.id);
      });
  }
  loadProduct(id){
    return this.productsService.getProduct(id).subscribe((result) => {
      let item = result.data.products[0];
      this.product = item;
      //this._seoService.updateTitle(this.product.shortName +this.title);
      this.loading = result.loading;
      this.errors = result.errors;
      this.imageSelected=this.urlPrefix+this.product.images[0].url;
    });
  }
  isArray(x){
    return Array.isArray(x);
  }
}
