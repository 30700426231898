<div id="main">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <!-- <img class="title" src="../../../assets/images/Logos for the website-02.png" alt="Purple Antler logo text Fusing Art & Engineering" > -->
      <div class="title">Catalog</div>
      <div class="overlay"></div>
    </div>
  </div>
  <div id="group2">
    
    <div *ngIf="!this.loading" class="row ">
      <div    *ngFor="let collection of this.collections" 
              class="col-sm-6 col-lg-4 collection justify-content-center" 
              (click)="navigateToCollection(collection)">
          <div class='btn-border'>
            <img [src]="this.urlPrefix + collection.ButtonPhoto.url" [alt]="collection.ButtonPhoto.alternativeText">
            <div class="overlay">
              <div class="title">
                  {{collection.name}}
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>


  <div class="contact-us-container">
    <div class="container">
      <div class="row">
        <span class="mobhide">
        <div class="col"><div class="text">Interested in learning more?</div></div>
        </span>
        <div class="col">
          <a class="contact-us" href="mailto:info@rivaprecision.com">
            <span>Request Catalog</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>