import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

// declare var hbspt: any;

@Component({
  selector: 'app-hubspot-email-signup',
  templateUrl: './hubspot-email-signup.component.html',
  styleUrls: ['./hubspot-email-signup.component.scss'],
})
export class HubspotEmailSignUpComponent implements AfterViewInit {
  @Input() showDialog: number;
  @Output() onHideDialog = new EventEmitter();

  ngAfterViewInit() {
    // hbspt.forms.create({
    //   region: 'na1',
    //   portalId: '24376144',
    //   formId: 'b7c3f005-7508-4cb8-a42d-1877dc846739',
    //   target: '#hubspotEmailSignUp',
    // });
  }
}
