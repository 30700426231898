<div id="main">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <!-- <head>
        <div class="title">
          Testimonials
        </div>
      </head> -->
      <div class="title"> Testimonials </div>
      <div class="overlay"></div>
    </div>
  </div>
  <div id="group2">
    <div class="col-container">
      <div class="col">
        <div class="content">
          <video *ngIf="this.testimonials[0].images[0].url.slice(-4) == '.mp4'" class="video" controls>
            <source src="{{this.urlPrefix + this.testimonials[0].images[0].url}}" type="video/mp4">
          </video>

          <img id='img1' *ngIf="this.testimonials[0].images[0].url.slice(-4) != '.mp4'" class="photo"
            src="{{this.urlPrefix + this.testimonials[0].images[0].url}}">
        </div>
      </div>
      <div class="col">
        <div class="box">
          <markdown ngPreserveWhitespaces [data]="this.testimonials[0].testimonial" class="testimonial"></markdown>
          <h2 class="byline">
            {{this.testimonials[0].name}}
            <br>
            {{this.testimonials[0].title}}
            <br>
            {{this.testimonials[0].company}}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div id="group3">
    <div *ngIf="!this.loading" class="row">
      <div id="group4">
        <div class="col-12">
          <div class="left"></div>
          <div class="right">
            <img id='img'
              src="https://strapi.rivaprecision.com/uploads/Elliot_Young_Jewelry_Pyramids_Collection_a7d5a6888d.jpg"
              style="width:100%;">
          </div>
        </div>
        <div class="container">
          <div *ngIf="!this.loading" class="row">
            <div class="col">
              <div class="content">
                <markdown ngPreserveWhitespaces [data]="this.testimonials[1].testimonial" class="testimonial">
                </markdown>
                <h2 class="byline">
                  {{this.testimonials[1].name}}
                  <br>

                  {{this.testimonials[1].title}}

                  {{this.testimonials[1].company}}
                </h2>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>