<div id="divBody">
  <!-- <div class="hubspot-forms">
    <div id="hubspotForm2"></div>
  </div> -->
</div>

<div class="row map" style="padding: 0 !important; margin: 0">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.284306638459!2d-74.02374090488881!3d40.64566289004333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25aac76e59f3f%3A0x855d73a142b9a862!2s140%2058th%20St%20%238B%2C%20Brooklyn%2C%20NY%2011220%2C%20USA!5e0!3m2!1sen!2sph!4v1587758855588!5m2!1sen!2sph"
  ></iframe>
</div>

<!--newsletter here-->
<div
  class="row divNewsLetter d-flex align-items-center"
  style="width: calc(100% + 15px)"
>
  <div class="col container">
    <div class="newsletter-text">
      <h2
        style="
          color: #eed253;
          font-family: Alegreya Sans;
          font-weight: 300 !important;
        "
      >
        Newsletter
      </h2>
      <h4>Sign up for our newsletter to get exclusive info</h4>
    </div>
  </div>
  <div class="col container">
    <a
      href="https://mailchi.mp/3608919bad02/rivaprecision"
      class="btn btn-newsletter-signup center-position"
      style="
        position: absolute;
        width: 215px;
        height: 66px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      Sign Up Now
    </a>
  </div>
</div>

<!--Newsletter-->
<!-- <div id="divBody">
  <div class="content" style="padding-top: 200px;">
    <div id= "app-contact-form">
       <app-contact-form></app-contact-form> 
  </div>
  </div>      
</div>                  
    
 <div class="row map" style="padding: 0 !important; margin-top: 0px;">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.284306638459!2d-74.02374090488881!3d40.64566289004333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25aac76e59f3f%3A0x855d73a142b9a862!2s140%2058th%20St%20%238B%2C%20Brooklyn%2C%20NY%2011220%2C%20USA!5e0!3m2!1sen!2sph!4v1587758855588!5m2!1sen!2sph"></iframe>
  </div>
  
  

<div class="row divNewsLetter d-flex align-items-center" style="width: calc(100% + 15px);">
  <div class="col container">
    <div class="newsletter-text">
      <h2 style="color: #eed253; font-family : Alegreya Sans; font-weight: 300 !important;">Newsletter</h2>
      <h4>Sign up for our newsletter to get exclusive info</h4>
    </div>
  </div>
  <div class="col container">
    <a class="btn btn-newsletter-signup center-position" style="position: absolute; width: 215px; height: 66px;">
      Sign Up Now
    </a>
  </div>
</div> -->
