import gql from "graphql-tag";

const CATEGORIES_QUERY = gql`
  query Categories {
    blogCategories {
      id
      category
    }
  }
`;

export default CATEGORIES_QUERY;