import gql from "graphql-tag";

const BLOG_QUERY = gql`
query Blogs($slug: String!) {
  blogs( 
    where: {Slug: $slug}
    )
{
    id
    title
    Slug
    author
    byline
    teaser
    content
    datePosted
    blog_categories {
      id
      category
    }
    blog_year{
      id
      year
    }
    image {
      url
      alternativeText
    }
    seo_keywords
    seo_description
  }
blogCategories(sort:"category:asc") {
  id
  category
}
blogYears (sort:"year:desc"){
  id
  year
}
}
`;

export default BLOG_QUERY;