import { Component, OnInit, HostListener } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title = 'Jewelry Manufacturing Experts | Riva Precision Manufacturing';
  siteName = 'Riva Precision Manufacturing';
  W: any;
  displayVid: boolean = true;
  showDialog = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.W = window.innerWidth;
    let vid = document.getElementById('vidframe');
    let vW;
    let vH;
    if (this.W >= 1200) {
      vH = 445;
      vW = 900;
    } else if (this.W >= 992) {
      vH = 445 - 0.32 * (1200 - this.W);
      vW = 900 - 0.64 * (1200 - this.W);
    } else if (this.W >= 768) {
      vH = 378;
      vW = 768;
    } else {
      vH = this.W * (445 / 900);
      vW = this.W;
    }
    vid.setAttribute('height', '' + vH);
    vid.setAttribute('width', '' + vW);
  }
  constructor(public _seoService: SeoService) {
    //this._seoService.updateTitle(this.title);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._seoService.updatePageSEO('home', window.location.href);
  }

  hidethumb() {
    this.onResize();
    let thumb = document.getElementById('vidthumb');
    let vid = document.getElementById('vidframe');

    thumb.style.visibility = 'hidden';
    vid.style.display = 'block';
    vid.style.visibility = 'visible';
    vid.setAttribute(
      'src',
      'https://www.youtube.com/embed/int3Sl4PUDc?autoplay=1&modestbranding=1&controls=0&fs=0'
    );

    this.displayVid = false;
  }

  setDialogVisibility() {
    this.showDialog = !this.showDialog;
  }
  
  navigateToUrl(url) {
    window.location.href = url;
  }
}
