<div class="footer-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-8 col-lg-4">
        <div class="widget row">
          <img
            class="footer-logo"
            src="https://api.rivapm.com/images/riva-logo.svg"
          />
        </div>
        <div class="widget row">
          <div class="col info">
            <h5>
              <strong>RIVA Precision</strong><br />
              140 58th St, Unit 8B<br />
              Brooklyn, NY 11220
            </h5>
          </div>
          <div class="col social">
            <div class="row">
              <div class="col-2 col-sm-4 col-md-2">
                <a href="https://twitter.com/RIVAPrecisionNY"
                  ><i class="fa fa-twitter"></i
                ></a>
              </div>
              <div class="col-2 col-sm-4 col-md-2">
                <a href="https://www.facebook.com/rivaprecision"
                  ><i class="fa fa-facebook"></i
                ></a>
              </div>
              <div class="col-2 col-sm-4 col-md-2">
                <a href="https://www.instagram.com/rivaprecision/?hl=en"
                  ><i class="fa fa-instagram"></i
                ></a>
              </div>
              <div class="col-3 col-sm-6 col-md-3">
                <a
                  href="https://www.linkedin.com/company/riva-jewelry-manufacturing-inc-/"
                  ><i class="fa fa-linkedin"></i
                ></a>
              </div>
              <div class="col-3 col-sm-6 col-md-3">
                <a
                  href="https://www.youtube.com/channel/UCZ3npuiU7hqdwjgeh6xPixg"
                  ><i class="fa fa-youtube-play"></i
                ></a>
              </div>
            </div>
            <div class="row" style="text-align: left !important">
              <div class="col">
                <div style="font-size: 16px">Phone: 718.361.3100</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-sm-2 footer-nav-menu">
        <div class="row">
          <a routerLink="">Home</a>
        </div>
        <div class="row">
          <a routerLink="/about">Who We Are</a>
        </div>
        <div class="row">
          <a routerLink="/why-riva">Why Riva</a>
        </div>
        <div class="row">
          <a routerLink="/contact">Contact Us</a>
        </div>
        <div class="row">
          <a routerLink="/job-inquiries">Job Inquiries</a>
        </div>
      </div>

      <div class="col-6 col-sm-2 footer-nav-menu">
        <div class="row">
          <a routerLink="/catalog">Catalog</a>
        </div>
        <div class="row">
          <a routerLink="/blog">Blog</a>
        </div>
        <div class="row">
          <a routerLink="/capabilities">Capabilities</a>
        </div>
        <div class="row">
          <a routerLink="/initiatives">Initiatives</a>
        </div>
      </div>

      <div class="col-sm-8 col-lg-4 icons" style="padding-top: 40px">
        <div class="row">
          <div class="column" style="padding-top: 20px">
            <a target="_blank" href="https://www.mjsa.org/">
              <img
                src="https://strapi.rivaprecision.com/uploads/RIVA_member_of_MJSA_fbbabf6624.png"
                alt="RIVA is a proud member of MJSA (Manufacturing Jewelers & Suppliers of America) "
              />
            </a>
          </div>

          <div class="column">
            <a target="_blank" href="https://jvclegal.org/">
              <img
                src="https://strapi.rivaprecision.com/uploads/RIVA_member_of_JVC_9f375d103c.png"
                alt="RIVA is a proud member of The Jewelers Vigilance Committee"
              />
            </a>
          </div>

          <div class="column">
            <a target="_blank" href="https://www.jewelersboard.com/">
              <img
                src="https://strapi.rivaprecision.com/uploads/RIVA_member_of_JBT_49033413d1.png"
                alt="RIVA is a proud member of The Jewelers Board of Trade"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container copyright-container">
    <div>
      <p>&copy; {{ copyrightYear }} Riva Precision</p>
    </div>
  </div>
</div>
