<div id="main">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="title">Blog</div>
      <div class="overlay"></div>
    </div>
  </div>

  <div id="group2">
    <div class="container">
      <h1
        *ngIf="
          this.selected.category.category != 'All' ||
          this.selected.year.year != 'All Time'
        "
      >
        {{ this.selected.category.category }} Articles from
        {{ this.selected.year.year }}
      </h1>
      <div class="row">
        <div class="col-12 col-sm-8 col-lg-9 col-xl-10">
          <div
            class="latest"
            *ngIf="
              this.pageSelected == 1 &&
              this.selected.category.category == 'All' &&
              this.selected.year.year == 'All Time' &&
              !this.blogsLoading
            "
          >
            <div class="flex">
              <span class="mobhide"
                ><img
                  [src]="this.urlPrefix + this.firstBlog.image[0].url"
                  [alt]="this.firstBlog.image[0].alternativeText"
                />
              </span>
              <div class="card">
                <h2>The Latest</h2>
                <h6>{{ this.firstBlog.datePosted }}</h6>
                <h1>{{ this.firstBlog.title }}</h1>
                <p style="overflow: hidden; text-overflow: ellipsis">
                  {{ this.firstBlog.teaser }}
                </p>
                <div class="read-more" (click)="navigateToBlog(firstBlog)">
                  Read More
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngFor="let blog of this.blogs" class="col-md-6 col-lg-4 card">
              <img
                [src]="this.urlPrefix + blog.image[0].url"
                [alt]="blog.image[0].alternativeText"
              />
              <h6>{{ blog.datePosted }}</h6>
              <h2>{{ blog.title }}</h2>
              <p>{{ blog.teaser }}</p>
              <div class="read-more" (click)="navigateToBlog(blog)">
                Read More
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <div class="row pagination">
                <div *ngFor="let pg of this.pages" class="col-1">
                  <a
                    class="underline-on-hover"
                    (click)="
                      this.router.navigate([
                        'blog',
                        {
                          category: this.selected.category.id,
                          year: this.selected.year.id,
                          page: pg
                        }
                      ])
                    "
                    [ngStyle]="{
                      color: pg == this.pageSelected ? '#ffffff' : '#eed253'
                    }"
                  >
                    {{ pg }}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-4"></div>
          </div>
          -
        </div>

        <div class="col-12 col-sm-4 col-lg-3 col-xl-2 filters">
          <h4>Categories</h4>
          <ul *ngIf="this.blogCategories">
            <li *ngFor="let cat of this.blogCategories">
              <a
                class="underline-on-hover"
                (click)="
                  this.router.navigate([
                    'blog',
                    {
                      category:
                        cat.id == this.selected.category.id ? 0 : cat.id,
                      year: this.selected.year.id,
                      page: 1
                    }
                  ])
                "
                [ngStyle]="{
                  color:
                    cat.id == this.selected.category.id ? '#eed253' : '#ffffff'
                }"
              >
                {{ cat.category }}
              </a>
            </li>
          </ul>

          <h4>Year</h4>
          <ul *ngIf="this.blogYears">
            <li *ngFor="let yr of this.blogYears">
              <a
                class="underline-on-hover"
                (click)="
                  this.router.navigate([
                    'blog',
                    {
                      category: this.selected.category.id,
                      year: yr.id == this.selected.year.id ? 0 : yr.id,
                      page: 1
                    }
                  ])
                "
                [ngStyle]="{
                  color: yr.id == this.selected.year.id ? '#eed253' : '#ffffff'
                }"
              >
                {{ yr.year }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
