import gql from "graphql-tag";

const ALL_BLOGS_QUERY = gql`
query allBlogs {
  blogs( 
    sort:"datePosted:desc")
{
    id
    title
    Slug
    author
    byline
    teaser
    datePosted
    blog_categories {
      id
      category
    }
    blog_year{
      id
      year
    }
    image {
      url
    }
  }
blogCategories(sort:"category:asc") {
  id
  category
}
blogYears (sort:"year:desc"){
  id
  year
}
}
`;

export default ALL_BLOGS_QUERY;