import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Riva Precision Manufacturing';
  'ContactForm';
  showDialog = false;

  ngOnInit() {
    this.showDialog = !localStorage.getItem('hubspot-signup-dialog-hidden');
  }
  onHideDialog() {
    this.showDialog = false;
    localStorage.setItem('hubspot-signup-dialog-hidden', 'true');
  }
}
