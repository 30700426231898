<div class="new-site-banner">
  <span>
    You are currently viewing our old shop. Visit our new shop at
    <a (click)="goToShop()" class="riva-link">rivaprecision.com</a></span
  >
</div>
<nav
  id="navbar"
  class="navbar navbar-expand-xl navbar-dark"
  style="width: 100vw"
>
  <a class="navbar-brand justify-content-center" href="#">
    <img
      id="navLogo"
      src="https://api.rivapm.com/images/riva-logo.svg"
      height="50"
      alt="mdb test logo"
    />
  </a>

  <button
    id="btnMobileNav"
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleCollapsed()"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    id="navbarNav"
    [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }"
  >
    <ul id="marketingNav" class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link underline-on-hover" (click)="navigateTo('home')"
          >HOME</a
        >
      </li>
      <li class="nav-item active">
        <a class="nav-link underline-on-hover" (click)="navigateTo('about')"
          >WHO WE ARE</a
        >
      </li>
      <li class="nav-item active">
        <a
          class="nav-link underline-on-hover"
          (click)="navigateTo('capabilities')"
          >CAPABILITIES</a
        >
      </li>
      <!--<li class="nav-item active">
          <a class="nav-link underline-on-hover" href="/lookbook">LOOKBOOK</a>
        </li> -->

      <li class="nav-item active">
        <div class="dropdown">
          <a
            class="nav-link underline-on-hover"
            (click)="navigateToUrl('https://rivaprecision.com')"
            >SHOP</a
          >
          <div class="dropdown-content">
            <a
              *ngFor="let item of this.collections"
              class="row"
              (click)="navigateToUrl(item.url)"
              style="cursor: pointer"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </li>
      <li class="nav-item active">
        <a class="nav-link underline-on-hover" (click)="navigateTo('blog')"
          >BLOG</a
        >
      </li>
      <li class="nav-item active">
        <a
          class="nav-link underline-on-hover"
          (click)="navigateTo('initiatives')"
          >INITIATIVES</a
        >
      </li>
      <li class="nav-item active">
        <a
          class="nav-link underline-on-hover"
          (click)="navigateTo('testimonials')"
          >TESTIMONIALS</a
        >
      </li>
      <!-- <li class="nav-item active">
        <div class="dropdown">
          <a class="nav-link underline-on-hover">CONTACT</a>
          <div class="dropdown-content">
            <a
              class="row"
              (click)="navigateTo('contact')"
              style="cursor: pointer"
              >Contact Us</a
            >
            <a
              class="row"
              (click)="navigateTo('job-inquiries')"
              style="cursor: pointer"
              >Job Inquiries</a
            >
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</nav>
