<div id="divBody">
  <!-- <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="title">Contact Us</div>
      <div class="overlay"></div>
    </div>
  </div> -->
  <!-- <div class="hubspot-forms">
    <div id="hubspotForm1"></div>
  </div> -->
  <div class="content">
    <div class="row contentHeader" style="padding-bottom: 0 !important">
      <!-- <div class="col-2"></div> -->
      <!--<div class="col-12 divHeader">
        <h4>Get in touch</h4>
        <h1>Contact Us</h1>
      </div>-->
    </div>
    <div class="row" style="padding-top: 0 !important; padding-bottom: 50px">
      <!-- <div class="col-12 divHeader"> -->
      <!-- <div class="col-6 border"> -->
      <!-- <div class="col-2" style="max-height: 435px;"></div> -->

      <div class="col-5 staticContactUs">
        <!-- <div class="border">
            <div class="row">
              <div class="col-10">
                <div class="col-6 divLabels contact-info" style="height: calc(100% - 30%); max-height: 435px;">

                  <h3>
                    RIVA Precision <br/>
                    140 58th Street, Unit 8B <br/>
                    Brooklyn, NY 11220 <br/>
                  </h3>
        
                  <h3>
                    718.361.3100 
                    <br/>
                    <a href="mailto:info@rivaprecision.com">info@rivaprecision.com </a>
                  </h3>
                  
                </div>
              </div>
            </div>
          </div> -->
      </div>
      <div class="col-6">
        <!-- <form class="text-center border border-light p-5" [ngClass]="{ 'form-success': sentToServer }" [formGroup]="formContact" (ngSubmit)="onSubmit($event)">
            <p class="h4 mb-3">Send us a Message</p>
            <input type="text" class="form-control mb-3" placeholder="Full Name" formControlName="fullName"
            [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
            <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
            </div>
            <input type="email" class="form-control mb-3" placeholder="E-mail Address" formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email is not in valid format</div>
            </div>
            <input type="text" class="form-control mb-3" placeholder="Company" formControlName="company"
            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">Company is required</div>
            </div>
            <input type="text" class="form-control mb-3" placeholder="Location" formControlName="location"
            [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
            <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
              <div *ngIf="f.location.errors.required">Location is required</div>
            </div>
            <input #website type="text" class="form-control mb-3" placeholder="Website (optional)" formControlName="website" [ngClass]="{ 'is-invalid': submitted && f.website.errors }">
            <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
              <div *ngIf="f.website.errors.required">Website is required</div>
              <div *ngIf="f.website.errors.pattern">Please enter valid Website url.</div>
            </div>
            <input type="text" class="form-control mb-3" placeholder="Title (optional)" formControlName="title">
            <label class="active">Select a Subject</label>
            <select class="browser-default custom-select mb-3" formControlName="subject">
              <option value="" disabled="">Select a Subject</option>
              <option *ngFor="let subject of arrSubject" [ngValue]="subject">
                {{ subject }}
              </option>
            </select>
  
            <div class="form-group">
              <textarea class="form-control rounded-0" rows="3" placeholder="Comment or Message(max of 5000 characters)" formControlName="message"
              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
              <div *ngIf="submitted && f.message.errors" class="invalid-feedback" style="margin-top: 0px;">
                <div *ngIf="f.message.errors.required">Comment or Message is required</div>
                <div *ngIf="f.message.errors.maxlength">Comment or Message has exceeded 5000 Characters</div>
              </div>
            </div>
            <button class="btn btn-info btn-block waves-effect waves-light" type="submit">Send</button>
  
          </form>
          <div class="text-center border border-light p-5" [ngClass]="{ 'form-success': !sentToServer }">
              <div class="form-message" [ngClass]="{ 'form-success-message': success , 'form-error-message': !success }">
                {{ formSubmitMessage }}
              </div>
          </div> -->
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<div class="row map" style="padding: 0 !important; margin: 0">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.284306638459!2d-74.02374090488881!3d40.64566289004333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25aac76e59f3f%3A0x855d73a142b9a862!2s140%2058th%20St%20%238B%2C%20Brooklyn%2C%20NY%2011220%2C%20USA!5e0!3m2!1sen!2sph!4v1587758855588!5m2!1sen!2sph"
  ></iframe>
</div>

<!--newsletter here-->
<div
  class="row divNewsLetter d-flex align-items-center"
  style="width: calc(100% + 15px)"
>
  <div class="col container">
    <div class="newsletter-text">
      <h2
        style="
          color: #eed253;
          font-family: Alegreya Sans;
          font-weight: 300 !important;
        "
      >
        Newsletter
      </h2>
      <h4>Sign up for our newsletter to get exclusive info</h4>
    </div>
  </div>
  <div class="col container">
    <a
      href="https://mailchi.mp/3608919bad02/rivaprecision"
      class="btn btn-newsletter-signup center-position"
      style="
        position: absolute;
        width: 215px;
        height: 66px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      Sign Up Now
    </a>
  </div>
</div>

<!--Newsletter-->
<!-- <div id="divBody">
  <div class="content" style="padding-top: 200px;">
    <div id= "app-contact-form">
       <app-contact-form></app-contact-form> 
  </div>
  </div>      
</div>                  
    
 <div class="row map" style="padding: 0 !important; margin-top: 0px;">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.284306638459!2d-74.02374090488881!3d40.64566289004333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25aac76e59f3f%3A0x855d73a142b9a862!2s140%2058th%20St%20%238B%2C%20Brooklyn%2C%20NY%2011220%2C%20USA!5e0!3m2!1sen!2sph!4v1587758855588!5m2!1sen!2sph"></iframe>
  </div>
  
  

<div class="row divNewsLetter d-flex align-items-center" style="width: calc(100% + 15px);">
  <div class="col container">
    <div class="newsletter-text">
      <h2 style="color: #eed253; font-family : Alegreya Sans; font-weight: 300 !important;">Newsletter</h2>
      <h4>Sign up for our newsletter to get exclusive info</h4>
    </div>
  </div>
  <div class="col container">
    <a class="btn btn-newsletter-signup center-position" style="position: absolute; width: 215px; height: 66px;">
      Sign Up Now
    </a>
  </div>
</div> -->
