import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { ContactUsService } from 'src/app/services/contact-us.service';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

// declare var hbspt: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements AfterViewInit {
  @ViewChild('website') txtWebsite: ElementRef<HTMLInputElement>;

  arrSubject: any = [
    'Contract Manufacturing Inquiry',
    'Order Inquiry',
    'Request a Virtual Appointment',
    'Press Inquiry',
    'Jobs Inquiry',
  ];

  urlPattern: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  formContact = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
    website: new FormControl('', [Validators.pattern(this.urlPattern)]),
    title: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl('', [
      Validators.required,
      Validators.maxLength(5000),
    ]),
  });
  submitted = false;
  sentToServer = false;
  success = false;
  formSubmitMessage: string = '';

  constructor(
    public _seoService: SeoService,
    private service: ContactUsService
  ) {}

  // ngOnInit(): void {
  //   this.formContact.patchValue({subject: this.arrSubject[0]});
  //   window.scrollTo(0, 0);
  //   this._seoService.updatePageSEO('contact', window.location.href);
  //   this.subject.valueChanges.subscribe(value => {
  //     this.subjectChangeHandler(value);
  //   });
  // }

  ngAfterViewInit() {
    // <script>
    //       hbspt.forms.create({
    //         region: "na1",
    //         portalId: "24376144",
    //         formId: "3aed8d85-a07e-4850-8343-44f2de765f22",
    //       });
    //     </script>
    //   <script>
    //   hbspt.forms.create({
    //     region: "na1",
    //     portalId: "24376144",
    //     formId: "7fdd6b9c-92e8-41fc-986c-eecfeac64570",
    //   });
    // </script>
    // hbspt.forms.create({
    //   region: 'na1',
    //   portalId: '24376144',
    //   formId: '7fdd6b9c-92e8-41fc-986c-eecfeac64570',
    //   target: '#hubspotForm1',
    // });
    // hbspt.forms.create({
    //   region: 'na1',
    //   portalId: '24376144',
    //   formId: '3aed8d85-a07e-4850-8343-44f2de765f22',
    //   target: '#hubspotForm2',
    // });
  }

  // ngAfterViewInit(): void {
  //   this.subjectChangeHandler(this.arrSubject[0]);
  // }

  // get website() {
  //   return this.formContact.get('website') as FormControl;
  // }

  // get subject() {
  //   return this.formContact.get('subject') as FormControl;
  // }

  // subjectChangeHandler(value: string) {
  //   if(value === "Contract Manufacturing Inquiry" || value === "Request a Virtual Appointment") {
  //     this.website.setValidators([Validators.required,Validators.pattern(this.urlPattern)]);
  //     this.txtWebsite.nativeElement.placeholder = "Website";
  //   }
  //   else {
  //     this.website.setValidators([Validators.pattern(this.urlPattern)]);
  //     this.txtWebsite.nativeElement.placeholder = "Website (optional)";
  //   }
  //   this.website.updateValueAndValidity();
  // }

  // sendEmail(item:any): void {
  //   //let item: any = {fullName:"Anurag",email:"aaa@aa.com",company:"aaa",location:"aaaaa",website: "aaaa",title: "aaaa",subject: "aaaaaa",message:"aaaaa"};
  //   this.sentToServer = true;
  //   this.service.sendEmail(item).subscribe((result) => {
  //     console.log(result);
  //     if(result) {
  //       if(result.success) {
  //         this.success = true;
  //         this.formSubmitMessage = "Thank you for your message. A representative from the Riva Precision team will contact you within 24 hours (except weekends and holidays) regarding your inquiry. If you require immediate assistance, please give us a call at (718) 361-3100.";
  //       }
  //       else {
  //         console.log(result.message);
  //         this.success = false;
  //         this.formSubmitMessage = "Sorry your inquiry was not submitted due to some technical issues. Please try again or if you require immediate assistance, please give us a call at (718) 361-3100.";
  //       }
  //     }
  //     this.reset();
  //   },
  //   (error) => {
  //     console.log(error);
  //     this.success = false;
  //     this.reset();
  //   },
  //   () => {

  //   });
  // }

  // reset() {
  //   this.submitted = false;
  //   this.formContact.reset();
  //   this.formContact.markAsPristine();
  //   this.formContact.markAsUntouched();
  //   this.formContact.patchValue({subject: this.arrSubject[0]});
  //   this.subjectChangeHandler(this.arrSubject[0]);
  // }

  // get f(): { [key: string]: AbstractControl } {
  //   return this.formContact.controls;
  // }

  // onSubmit(event: any): void {
  //   this.submitted = true;
  //   if (this.formContact.invalid) {
  //     return;
  //   }
  //   console.log(JSON.stringify(this.formContact.value, null, 2));
  //   this.sendEmail(this.formContact.value);
  // }
}
