import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { ContactUsService } from 'src/app/services/contact-us.service';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

// declare var hbspt: any;

@Component({
  selector: 'app-job-inquiries',
  templateUrl: './job-inquiries.component.html',
  styleUrls: ['./job-inquiries.component.scss'],
})
export class JobInquiriesComponent implements AfterViewInit {
  ngAfterViewInit() {
    // hbspt.forms.create({
    //   region: 'na1',
    //   portalId: '24376144',
    //   formId: '3aed8d85-a07e-4850-8343-44f2de765f22',
    //   target: '#hubspotForm2',
    // });
  }
}
