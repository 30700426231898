import { Component, OnInit } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import { Subscription } from "rxjs";
import { SeoService } from 'src/app/services/seo.service';
@Component({
  selector: 'app-capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.scss']
})
export class CapabilitiesComponent implements OnInit {
  title = 'Capabilities - Riva Precision | American jewelry manufacturer';
  urlPrefix="https://strapi.rivaprecision.com";
  leftCapabilities:any = [];
  rightCapabilities:any = [];
  loading = true;
  errors: any;

  private queryCapabilities: Subscription;

  constructor(public _seoService: SeoService, public aboutService: AboutService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._seoService.updatePageSEO('capabilities', window.location.href);
    this.queryCapabilities = this.loadCapabilities();
  }
  ngOnDestroy() {
    this.queryCapabilities.unsubscribe();
  }
  loadCapabilities(){
    return this.aboutService.getCapabilities().subscribe((result) => {
      this.leftCapabilities = result.data.left;
      this.rightCapabilities = result.data.right;
      this.loading = result.loading;
      this.errors = result.errors;
    });
  }

}
