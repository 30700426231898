import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMasonryModule } from 'ngx-masonry';

import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { CapabilitiesComponent } from './components/capabilities/capabilities.component';

import { CatalogComponent } from './components/catalog/catalog.component';
import { ProductBrowseComponent } from './components/catalog/product-browse/product-browse.component';
import { ProductComponent } from './components/catalog/product-browse/product/product.component';

import { BlogComponent } from './components/blog/blog.component';
import { BlogContentComponent } from './components/blog/blog-content/blog-content.component';

import { CertifiationsComponent } from './components/certifiations/certifiations.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ContactComponent } from './components/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JobInquiriesComponent } from './components/job-inquiries/job-inquiries.component';
import { HubspotEmailSignUpComponent } from './components/hubspot-email-signup/hubspot-email-signup.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    BlogComponent,
    BlogContentComponent,
    AboutComponent,
    CatalogComponent,
    ProductBrowseComponent,
    ProductComponent,
    CapabilitiesComponent,
    TestimonialsComponent,
    CertifiationsComponent,
    ContactComponent,
    JobInquiriesComponent,
    HubspotEmailSignUpComponent,
  ],

  imports: [
    MarkdownModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    NgxMasonryModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
