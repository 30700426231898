import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from "apollo-angular";

import HISTORY_CARDS_QUERY from "../apollo/queries/about/history";
import CAPABILITIES from "../apollo/queries/about/capabilities";
import TESTIMONIALS_QUERY from "../apollo/queries/about/testimonials";

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(private apollo:Apollo) { }

  public getHistoryCards(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: HISTORY_CARDS_QUERY
    }).valueChanges;
  }
  public getCapabilities(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: CAPABILITIES
    }).valueChanges;
  }
  public getTestimonials(): Observable<any>{
    return this.apollo.watchQuery<any>({
      query: TESTIMONIALS_QUERY
    }).valueChanges;
  }

}
