import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params} from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { BlogsService } from '../../../services/blogs.service';
import { Subscription, Observable } from "rxjs";
import { CommonUtil } from 'src/app/services/commonUtil.service';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogContentComponent implements OnInit {

  title = 'Blog - Riva Precision | American jewelry manufacturer';
  firstBlog:any;
  blog:any;
  blogCategories:any = [];
  blogYears:any = [];
  blogSelected:string;
  urlPrefix= CommonUtil.SERVER_URL;
  data: any = {};
  loading = true;
  errors: any;

  private queryBlog: Subscription;

  constructor(public _seoService: SeoService,
              private route:ActivatedRoute,
              private router:Router,
              private blogService: BlogsService) { 
              }
              sub;
  ngOnInit(): void {
    this.queryBlog = this.load();
  }
  load(){
    
    this.route.params.subscribe((params: Params) =>  
      {console.log(params);
      if(params.id){
        this.blogSelected = params.id;
      }
      this.blog = this.blogService.getBlog(this.blogSelected)
      });
      return this.blogService.getBlog(this.blogSelected).subscribe((result) => {
        let item = result.data.blogs[0];
        this.blog = item;
        this.loading = result.loading;
        this.errors = result.errors;
        this.blogYears = result.data.blogYears;
        this.blogCategories = result.data.blogCategories;
        this._seoService.updateArticleMetaTags( this.blog.title, 
                                                window.location.href, 
                                                this.blog.seo_keywords,
                                                this.urlPrefix + this.blog.image[0].url, 
                                                this.blog.seo_description, 
                                                this.blog.datePosted, 
                                                this.blog.byline, 
                                                this.blog.blog_categories[0].category, 
                                                this.blog.seo_keywords.split(", "),)
      });
  }

}
