import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-certifiations',
  templateUrl: './certifiations.component.html',
  styleUrls: ['./certifiations.component.scss']
})
export class CertifiationsComponent implements OnInit {
  title = 'What makes Riva a leader in New York jewelry manufacturing';
  constructor(public _seoService: SeoService) { }

  ngOnInit(): void {
    
    this._seoService.updatePageSEO('initiatives', window.location.href);
    window.scrollTo(0, 0);
  }

}
