import gql from "graphql-tag";

const CAPABILITIES_QUERY = gql`
query Capabilities {
    left: capabilities( 
      sort:"id:desc", 
      where:{position:"left"})
  	{
        label
        body
    }
  	right: capabilities( 
      sort:"id:desc", 
      where:{position:"right"})
  	{
        label
    	body
    }
  }
`;

export default CAPABILITIES_QUERY;