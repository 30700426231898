import gql from "graphql-tag";

const COLLECTIONS_QUERY = gql`
query Collections{
    productCollections{
        id
        name
        Slug
        ButtonPhoto{
          url
          alternativeText
        }
        Hidden
      }
}
`;

export default COLLECTIONS_QUERY;