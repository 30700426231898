import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';
import { CommonUtil } from 'src/app/services/commonUtil.service';

const collectionSortOrder = [
  'Cable Chains',
  'Paper Clip Chains',
  'Fairmined Gold Chains',
  'Fairmined Gold Bridal',
  'Fairmined Gold Jewelry',
  'Fairmined Gold Stud Earrings',
  'Fairmined Gold Findings',
  'Fairmined Gold Wire',
  'Invisible Clasps',
];

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  title = 'Catalog - Riva Precision | American Jewelry Manufacturer';

  urlPrefix = CommonUtil.SERVER_URL;

  collections: any = [];
  loading = true;
  errors: any;

  private queryCollections: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public _seoService: SeoService,
    private productsService: ProductsService
  ) {}
  sub;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._seoService.updatePageSEO('catalog', window.location.href);
    this.queryCollections = this.loadCollections();
  }

  ngOnDestroy() {
    this.queryCollections.unsubscribe();
  }

  loadCollections() {
    return this.productsService.getProductCollections().subscribe((result) => {
      this.collections = result.data.productCollections
        .map((p) => ({
          ...p,
          sortOrder: collectionSortOrder.findIndex((c) => c === p.name),
        }))
        .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
      this.loading = result.loading;
      this.errors = result.errors;
    });
  }

  navigateToCollection(collection: any) {
    if (collection) {
      if (collection.Slug) {
        window.location.href = `https://shop.rivaprecision.com/collections/${collection.Slug}`;
        // this.router.navigate(['catalog/'+ collection.Slug])
      } else {
        console.error('Slug for Catalog ID ' + collection.id + '  is null');
      }
    }
  }
}
