import gql from "graphql-tag";

const PRODUCT_QUERY = gql`
query Products($slug: String!){
    products(where: {Slug: $slug}) {
        id
        shortName
        Slug
    	fullName
    	SKU
      	price
      	priceEnabled
        description
      	disclaimer
      	productAttributes
    	images{
          url
          alternativeText
        }
      	product_collection{
          id
          name
          BackgroundPhoto{
              url
          }
        }
        product_filter_values{
          id
          name
        }
      }
}
`;

export default PRODUCT_QUERY;