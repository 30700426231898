import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AboutService } from 'src/app/services/about.service';
import { Subscription } from "rxjs";
import { SeoService } from 'src/app/services/seo.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  title = 'About Riva: The journey of this American jeweler towards excellence';
  urlPrefix="https://strapi.rivaprecision.com";
  cards:any = [];
  loading = true;
  errors: any;

  private queryCards: Subscription;

  constructor( public _seoService: SeoService, public aboutService: AboutService) {
    
   }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._seoService.updatePageSEO('about', window.location.href);
    this.queryCards = this.loadCards();
  }
  ngOnDestroy() {
    this.queryCards.unsubscribe();
  }
  loadCards(){
    return this.aboutService.getHistoryCards().subscribe((result) => {
      this.cards = result.data.ourHistoryCards;
      this.loading = result.loading;
      this.errors = result.errors;
    });
  }

}
