import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription } from 'rxjs';

const collectionSortOrder = [
  'Cable Chains',
  'Paper Clip Chains',
  'Fairmined Gold Chains',
  'Fairmined Gold Bridal',
  'Fairmined Gold Jewelry',
  'Fairmined Gold Stud Earrings',
  'Fairmined Gold Findings',
  'Fairmined Gold Wire',
  'Invisible Clasps',
];
@Component({
  selector: 'riva-manufacturing-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  collapsed = true;
  toggleCollapsed(): void {
    let nav = document.getElementById('navbar');
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      nav.classList.remove('auto');
    } else {
      nav.classList.add('auto');
    }
  }
  sticky: boolean = false;
  elementPosition: any;
  collections: any = [];
  loading = true;
  errors: any;
  private queryCollections: Subscription;

  constructor(
    public router: Router,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    if (window.pageYOffset > 0) {
      document.getElementById('navbar').classList.add('scroll');
    } else {
      document.getElementById('navbar').classList.add('top');
    }
    // this.queryCollections = this.loadCollections();
    this.collections = [
      {
        name: 'Permanent Jewelry',
        url: 'https://rivaprecision.com/collections/permanent-jewelry'
      },
      {
        name: 'Charms',
        url: 'https://rivaprecision.com/collections/charms'
      },
      {
        name: 'Cable Chains',
        url: 'https://rivaprecision.com/collections/cable-chains'
      },
      {
        name: 'Paper Clip Chains',
        url: 'https://rivaprecision.com/collections/paper-clip-chains'
      },
      {
        name: 'Invisible Clasps',
        url: 'https://rivaprecision.com/collections/invisible-clasps'
      },
      {
        name: 'Fairmined Gold Chains',
        url: 'https://rivaprecision.com/collections/fairmined-gold-chains'
      },
      {
        name: 'Fairmined Gold Findings',
        url: 'https://rivaprecision.com/collections/fairmined-gold-findings'
      },
      {
        name: 'Fairmined Gold Bridal',
        url: 'https://rivaprecision.com/collections/fairmined-gold-bridal'
      },
      {
        name: 'Fairmined Gold Stud Earrings',
        url: 'https://rivaprecision.com/collections/fairmined-gold-stud-earrings'
      },
      {
        name: 'Fairmined Gold Wire',
        url: 'https://rivaprecision.com/collections/fairmined-gold-wire'
      },
    ]
  }

  ngOnDestroy() {
    this.queryCollections.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    let element = document.getElementById('navbar');

    if (window.pageYOffset > 0) {
      element.classList.add('scroll');
      element.classList.remove('top');
    } else {
      element.classList.add('top');
      element.classList.remove('scroll');
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside =
      document.getElementById('navbarNav').contains(targetElement) ||
      document.getElementById('btnMobileNav')?.contains(targetElement);
    if (!clickedInside) {
      this.handleMenuForNonDesktop();
    }
  }

  loadCollections() {
    return this.productsService.getProductCollections().subscribe((result) => {
      this.collections = result.data.productCollections
        .map((p) => ({
          ...p,
          sortOrder: collectionSortOrder.findIndex((c) => c === p.name),
        }))
        .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
      this.loading = result.loading;
      this.errors = result.errors;
    });
  }

  navigateTo(routePath: string) {
    this.handleMenuForNonDesktop();
    this.router.navigate([routePath]);
  }

  navigateToUrl(url) {
    this.handleMenuForNonDesktop();
    window.location.href = url;
  }

  navigateToCollection(collection: any) {
    if (collection) {
      this.handleMenuForNonDesktop();
      if (collection.Slug) {
        window.location.href = `https://rivaprecision.com/collections/${collection.Slug}`;
        // this.router.navigate(['catalog/'+ collection.Slug])
      } else {
        console.error('Slug for Catalog ID ' + collection.id + '  is null');
      }
    }
  }

  goToShop() {
    window.location.href = 'https://rivaprecision.com';
  }

  handleMenuForNonDesktop() {
    const btnMobileNav: any = document.getElementById('btnMobileNav');
    if (
      btnMobileNav &&
      window.getComputedStyle(btnMobileNav)['display'] !== 'none'
    ) {
      if (!this.collapsed) {
        this.toggleCollapsed();
      }
    }
  }
}
