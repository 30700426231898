import gql from "graphql-tag";

const BLOGS_QUERY = gql`
query Blogs($category: String! ,$year: String!){
  blogs( 
    sort:"datePosted:desc", 
    where:
    {
      blog_categories:{category_contains:$category},
      blog_year:{year_contains:$year}
    })
  {
    id
    title
    Slug
    author
    byline
    teaser
    datePosted
    blog_categories {
      id
      category
    }
    blog_year{
      id
      year
    }
    image {
      url
      alternativeText
    }
  }
}
`;

export default BLOGS_QUERY;