import { Component, ViewEncapsulation, OnInit ,ViewChild} from '@angular/core';

import { Router,ActivatedRoute, Params} from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription, Observable } from "rxjs";
import { SeoService } from 'src/app/services/seo.service';
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { CommonUtil } from 'src/app/services/commonUtil.service';

@Component({
  selector: 'app-product-browse',
  templateUrl: './product-browse.component.html',
  styleUrls: ['./product-browse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductBrowseComponent implements OnInit {
  title = ' Catalog - Riva Precision | American Jewelry Manufacturer';
  
  urlPrefix=CommonUtil.SERVER_URL;
  id: string;
  collection:any = {};
  allProducts:any = [];
  products:any = [];
  filters:any = [];
  selectedFilters:any ={};
  loading = true;
  errors: any;

  private queryCollection: Subscription;
  private queryFilteredCollection: Subscription;

  /*public masonryOptions: NgxMasonryOptions = {
    itemSelector: ".grid-item",
    //columnWidth:250,
    gutter: 0,
  };
  @ViewChild(NgxMasonryComponent,{static:false})
  public masonry: NgxMasonryComponent;*/

  constructor(private route:ActivatedRoute,
              private router:Router,
              public _seoService: SeoService,
              private productsService: ProductsService) { } 

  ngOnInit(): void {
    // window.scrollTo(0, 0);
    this.load();
  }
  ngOnDestroy() {
    this.queryCollection.unsubscribe();
  }
  load(){
    this.route.params.subscribe((params: Params) =>  
      {
        window.location.href= `https://shop.rivaprecision.com/collections/${params.collection}`;
        // this.id = params.collection;
       // this.queryCollection = this.loadCollection(this.id);
      });
      
  }
  loadCollection(id){
    return this.productsService.getProductCollection(id).subscribe((result) => {
      this.collection = result;
      this._seoService.updatePageMetaTags(this.collection.seo_title, window.location.href, this.collection.seo_keywords, this.urlPrefix + this.collection.seo_image.url, this.collection.seo_description);
      this.allProducts = result.products;
      this.products = this.allProducts;
      this.filters = result.product_filters;
      this.loading = false;
      this.errors = null;
      /*this.collection = result.data.productCollection;
      this._seoService.updatePageMetaTags(this.collection.seo_title, window.location.href, this.collection.seo_keywords, this.urlPrefix + this.collection.seo_image.url, this.collection.seo_description);
      this.allProducts = result.data.products;
      this.products = this.allProducts;
      this.filters = result.data.productFilters;
      this.loading = result.loading;
      this.errors = result.errors;*/
    });
  }
  filterProducts(){
    this.products = this.productsService.getFilteredProducts(this.selectedFilters,this.allProducts);
    /*this.masonry.reloadItems();
    this.masonry.layout();
    setTimeout( ()=>{
      this.masonry.reloadItems();
      this.masonry.layout();
    },1000);*/
  }
  navigateToProduct(product: any)
  {
    if(product)
    {
      if(product.Slug)
      {
        this.router.navigate(['catalog/product/'+ product.Slug])
      }
      else
      {
        console.error("Slug for Product ID " + product.id + "  is null");
      }
    }
  }
}
