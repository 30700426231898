<div id="main">
    <div id="group1" class="parallax__group">
      <div class="parallax__layer parallax__layer--base">
        <img class="title" src="../../../assets/images/WL-02.png" alt="Purple Antler logo text Fusing Art & Engineering" >
        <!--<div class="title">Blurring the Line Between Engineering and Art</div>-->
      </div>
    </div>
  
    <div id="group4" class="parallax__group">
      <div class="parallax__layer parallax__layer--base">
        <div class="overlay"></div>
  
        <div class="row">
          <div class="col-12 container">
            <div class="description">
              <p style="text-align: center;">
                We provide designer-driven manufacturing solutions that are devised, crafted, and forged using the sharpened edge of
                enduring expertise. With all of our manufacturing components located in-house, our inventive application of time-tested
                techniques provide our clients, both prospective and active, a sense of inevitable satisfaction.
              </p>
            </div>
          </div>
        </div>
  
        <div class="container">
          <div class="row why-riva" style="padding-top: 0 !important; padding-bottom: 0 !important;">
            <!--<span class="mobhide">-->
            <div class="col image-left">
              
                  <div class="shadow"></div>
              
              <div class="div-img">
                <img src="https://api.rivapm.com/images/our-values-1.jpg" />
              </div>
            </div>
         <!--</span>-->
  
            <div class="col info">
              <div class="info-box">
                <h1 class="info-header">
                  Environmental Stewardship
                </h1>
                <p class="info-content">
                  Riva cares for the environment by monitoring our energy usage and environmental impact.
                  Simple things like installing energy efficient lighting, reducing our energy consumption during working hours
                  and ongoing performance maintenance of our machinery ensures optimal output with minimal waste.
                  Water, our most precious resource, is always filtered before being released back to our community.
                  The metal byproducts of our manufacturing process are also reclaimed and recycled.
                  These are just a couple of examples of how we practice conservation.
                  Our desire is to preserve and protect what we have been given so that future generations can enjoy what we do today.
                </p>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
    <div id="group5" class="parallax__group row" style="margin: 0 !important;">
      <div class="parallax__layer parallax__layer--base">
        <div class="overlay"></div>
  
        <div class="container">
          <div class="row why-riva" style="padding-top: 0 !important; padding-bottom: 0 !important;">
  
            <div class="col info">
              <div class="info-box">
                <h1 class="info-header">
                  Ethical Responsibility
                </h1>
                <p class="info-content">
                  We live by the Golden Rule so we will never buy products (precious metals, gems, etc.) from sources we do not know or trust.
                  We will not create work conditions that deprive our team members of human dignity or a safe environment.
                  And we will never compromise ourselves for a shortcut. These principles are non-negotiable and are part of the Riva DNA.
                </p>
              </div>
            </div>
  
            <div class="col image-right">
              <div class="shadow"></div>
  
              <div class="div-img">
                <img src="https://api.rivapm.com/images/webpic2.jpg" />
              </div>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  
    <div id="group2" class="parallax__group">
      <div class="parallax__layer parallax__layer--base">
        <div class="overlay"></div>
        <div class="row" style=" margin: 0 !important;">
          <div class="col-12 info-box" >
            <h5 class="info-header">Our History</h5>
            <h2 class="info-subject">Manufacturing Excellence since 1988</h2>
            <p class="info-content">
              RIVA began as one-man working nights on a bench in his basement. Thirty years later, we are a product development and vertically integrated manufacturing plant occupying a 37,500 square foot space with over 140 experience employees. The journey in between was full of dedication, innovation, and perseverance: all the same standards we live by today.
            </p>
          </div>
        </div>
  
        <div *ngIf="!this.loading" class="row">

          <div *ngFor="let card of this.cards" class="col col-12 col-lg-6 col-xl-4">
            <div class="front" [ngStyle]="{'background-image': 'url(' + this.urlPrefix + card.image.url +')'}">
              <p>{{card.year}}</p>
              <div class="back">
                <p>{{card.info}}</p>
              </div>
            </div>
          </div>

  
      </div>
    </div>
  
    <div class="mission-vision">
      <div class="row" style="margin: 0 !important;">
        <div class="col left"></div>
        <div class="col right">
          <div class="row">
            <div class="col">
              <h5>Our Mission</h5>
              <h2>
                To honor God through our service, process and product excellence.
              </h2>
            </div>
            <div class="col">
              <h5>Our Vision</h5>
              <h2>
                To become the leader in every industry we pursue while serving our customers, suppliers and employees.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  
     <!--div id="group7" class="parallax__group">
        <div class="parallax__layer parallax__layer--base">
            <div class="overlay"></div>
  
            <div class="container">
                <div class="row why-riva" style="padding-top: 0 !important; padding-bottom: 0 !important;">
  
                    <div class="col info">
                        <div class="info-box">
                            <h1>
                                Strong Industry Partnerships
                            </h1>
                            <p class="info-content">
                                No company can flourish in a vacuum. RIVA realizes the importance of strong industry and community partnerships to our health and growth.
                                We are proud to be members of and show our support to the following organizations and communities.
                            </p>
                        </div>
                    </div>
  
                    <div class="col partners">
                        <div class="row">
                            <div class="col">
                                <a href="http://www.jewelersboard.com/" target="_blank">
                                    <img src="https://api.rivapm.com/images/jbt-white.jpg" alt="JBT">
                                </a>
                            </div>
                            <div class="col">
                                <a href="https://mjsa.org/" target="_blank">
                                    <img src="https://api.rivapm.com/images/mjsa-white.jpg" alt="MJSA">
                                </a>
                            </div>
                            <div class="col">
                                <a href="https://www.24karatclub.org/" target="_blank">
                                    <img src="https://api.rivapm.com/images/24kclub-white.jpg" alt="24KCLUB">
                                </a>
                            </div>
                            <div class="col">
                                <a href="https://www.bklynarmyterminal.com/" target="_blank">
                                    <img src="https://api.rivapm.com/images/bkln-at-white.jpg" alt="BKLYN">
                                </a>
                            </div>
                        </div>
                    </div>
  
                </div>
            </div>
  
        </div>
        <div class="parallax__layer parallax__layer--back"></div>
    </div--> 
  
  </div>
  