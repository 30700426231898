<div id="main" style="overflow-x: hidden; overflow-y: hidden">
  <div id="group1" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <!-- <img class="title" src="../../../assets/images/WL-05.png" alt="Purple Antler logo text initiatives" > -->
      <!--<div class="title">Certifications</div>-->
      <div class="title">Initiatives</div>
      <div class="overlay"></div>
    </div>
    <div class="parallax__layer parallax__layer--back"></div>
  </div>

  <div id="group2" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="overlay"></div>

      <div class="container">
        <div class="row">
          <div class="col info">
            <div class="info-box">
              <h2>Fairmined</h2>
              <p class="info-content">
                RIVA is excited to announce that we are currently offering chain
                and custom pieces manufactured from 100%&nbsp;<em
                  ><strong
                    ><a
                      href="http://www.fairmined.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >certified fairmined gold</a
                    ></strong
                  ></em
                >. Gold purchased with the fairmined standard supports artisan,
                fair trade mines in South America and allows these communities
                to implement social welfare programs and environmental
                initiatives.
              </p>
              <p id="second" class="info-content">
                Each batch is closely monitored and can be traced back to its
                origin mine. And each step along the process is held to the
                highest moral and quality standards. Please See our Fairmined
                Page for more information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parallax__layer parallax__layer--back"></div>
  </div>

  <div id="group3" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="overlay"></div>

      <div class="container">
        <div class="row">
          <div class="col info">
            <div class="info-box">
              <h2>Mercury Free Mining</h2>
              <p class="info-content">
                Global mercury pollution is one of the greatest threat’s society
                faces today, potentially affecting every community in every part
                of our world as mercury never breaks down to a non-toxic state.
              </p>
              <p id="second" class="info-content">
                RIVA Precision Manufacturing proudly supports the&nbsp;<em
                  ><strong
                    ><a
                      href="https://www.mercuryfreemining.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Mercury Free Mining Challenge</a
                    >,&nbsp;</strong
                  ></em
                >an organization dedicated to removing mercury from the gold
                mining industry and offering a&nbsp;<strong
                  >$1,000,000 prize&nbsp;</strong
                >to the team or individual that discovers an environmentally
                friendly, affordable means of separating crushed, finely divided
                gold from its ore.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="group4" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="overlay"></div>

      <div class="container">
        <div class="row">
          <div class="col info">
            <div class="info-box">
              <h2>Recycled Metal</h2>
              <p class="info-content">
                The environmental and social costs of metals mining include
                using as much as 10 per cent of world energy, contributing to
                poisonous emissions and human rights abuses, as well as vast
                landscape damage.
              </p>
              <p id="second" class="info-content">
                &nbsp;To produce sustainable jewelry, RIVA Precision
                Manufacturing uses recycled sterling silver from&nbsp;<em>
                  <a
                    href="https://www.unitedpmr.com/environmental_commitment.php"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><strong>United Precious Metals</strong></a
                  ></em
                >. United is a recycled silver vendor certified by&nbsp;<em
                  ><a
                    href="https://www.scsglobalservices.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>SCS Global Services</strong></a
                  ></em
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="group5" class="parallax__group">
    <div class="parallax__layer parallax__layer--base">
      <div class="overlay"></div>

      <div class="container">
        <div class="row">
          <div class="col info">
            <div class="info-box">
              <h2>Conflict Free Metals</h2>
              <p class="info-content">
                RIVA Precision Manufacturing is committed to using strictly
                conflict free and/or fair-mined metals to manufacture high-end,
                ethical jewelry. Our suppliers are certified by the&nbsp;<em
                  ><strong
                    ><a
                      href="https://www.responsiblejewellery.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Responsible Jewelry Council</a
                    ></strong
                  ></em
                >.
              </p>
              <p id="second" class="info-content">
                Their initiatives guarantee&nbsp;Conflict-free gold from a
                conflict-free smelter as certified by Conflict-Free Sourcing
                Initiative (CFSI) to the Conflict-Free Smelter Program (CFSP),
                conflict-free gold conforming to the Signet Responsible Sourcing
                Protocol (SRSP) criteria for refinery sources and recycled gold
                as certified by SCS Global Services to the SCS Recycled Content
                Standard. This also covers the sourcing of 3TG. Additionally,
                all the silver we use is certified recycled by SCS Global
                services. (see more above)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact-us-container">
    <div id="container1" class="container">
      <div
        class="row"
        style="padding: 0 !important; display: flex; align-items: center"
      >
        <span id="Text" class="col mobhide" style="margin-top: 0"
          >Interested in working with RIVA?</span
        >

        <div class="col" style="margin-top: 0">
          <a class="contact-us" href="/contact">
            <span>Contact Us</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
