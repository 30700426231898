import gql from "graphql-tag";

const TESTIMONIALS_QUERY = gql`
query getTestimonials{
    testimonials{
      id
      testimonial
      images{
        url
        alternativeText
        caption
      }
      name
      title
      company
    }
    
  }
`;

export default TESTIMONIALS_QUERY;