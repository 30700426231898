import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtil } from './commonUtil.service';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }

  public sendEmail(item:any): Observable<any>{
    let url: string = CommonUtil.SERVER_URL + "/contact-us-forms/email";
    return this.http.post<any>(url,item);
  }

}
